import { Card, CardContent, CardHeader, Grow, Modal } from '@mui/material';
import React, { createContext, useContext, useMemo, useState } from 'react';

type UnderlyingType = {
  id: string;
  title: string;
  subtitle: string;
  content: React.ReactNode;
};

type ModalSubContext = {
  closeModal: () => void;
};

type ModalContextType = {
  current: UnderlyingType[];
  onChange: React.Dispatch<React.SetStateAction<UnderlyingType[]>>;
};

const ModalContext = createContext({} as ModalContextType);

const CurrentModalContext = createContext({} as ModalSubContext);

export default function ModalProvider(props: React.PropsWithChildren) {
  const { children } = props;
  const [content, setContent] = useState<UnderlyingType[]>([]);

  const value = useMemo(
    () => ({
      current: content,
      onChange: setContent,
    }),
    [content],
  );

  return (
    <ModalContext.Provider value={value}>
      {children}
      {content.map((item, index) => (
        <Modal
          key={index}
          open={true}
          sx={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 2,
            '.MuiPaper-root': {
              display: 'flex',
              flexDirection: 'column',
            },
          }}
          onClose={() => setContent([])}
        >
          <Grow in={true}>
            <Card
              sx={{
                maxWidth: '100%',
                maxHeight: '100%',
                flexGrow: 0,
                flexShrink: 1,
                minWidth: 400,
              }}
            >
              <CardHeader title={item.title} subheader={item.subtitle} />
              <CardContent
                sx={{
                  pt: 0,
                  minHeight: 0,
                  display: 'flex',
                  flexDirection: 'column',
                  '& > *': {
                    overflowY: 'auto',
                  },
                }}
              >
                <CurrentModalContext.Provider
                  value={{
                    closeModal: () =>
                      setContent((prev) =>
                        prev.filter((i) => i.id !== item.id),
                      ),
                  }}
                >
                  {item.content}
                </CurrentModalContext.Provider>
              </CardContent>
            </Card>
          </Grow>
        </Modal>
      ))}
    </ModalContext.Provider>
  );
}

export function useModal() {
  const context = useContext(ModalContext);

  return {
    showModal: (
      title: UnderlyingType['title'],
      content: UnderlyingType['content'],
      subtitle: UnderlyingType['subtitle'] = '',
    ) => {
      const id = window.crypto.randomUUID();
      context.onChange((prev) => [
        ...prev,
        {
          id,
          title,
          subtitle,
          content,
        },
      ]);
      return id;
    },
  };
}

export function useCurrentModal() {
  const context = useContext(CurrentModalContext);

  if (!context) {
    return null;
  }

  return context;
}
