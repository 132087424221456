import {
  type AlertProps,
  type AppBarProps,
  createTheme,
  type CSSInterpolation,
  LinkProps,
  type TextFieldProps,
  type Theme,
  type ThemeOptions,
} from '@mui/material';
import { common, cyan, orange } from '@mui/material/colors';
import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';

/* eslint-disable no-unused-vars */
declare module '@mui/material/styles' {
  interface Palette {
    dividerSecondary: string;
  }

  interface TypeBackground {
    inverted: string;
  }

  interface PaletteOptions {
    dividerSecondary?: string;
  }
}
/* eslint-enable no-unused-vars */

const baseTheme: ThemeOptions = {
  typography: {
    fontFamily: 'Bahnschrift',
    fontWeightRegular: 300,
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({
          background: theme.palette.background.paper,
          border: `1px solid ${theme.palette.divider}`,
        }),
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            background: 'unset',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
      defaultProps: {
        size: 'small',
        variant: 'outlined',
      },
      variants: [
        {
          props: { size: 'medium' },
          style: {
            fontWeight: 500,
            fontSize: '1em',
          },
        },
      ],
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root({ ownerState }: { theme?: Theme; ownerState: AppBarProps }) {
          if (ownerState.variant === 'outlined') {
            return {
              '&:not(#a)': {
                borderTopWidth: 0,
                borderLeftWidth: 0,
                borderRightWidth: 0,
              },
            };
          }

          return {};
        },
      },
    },
    MuiTextField: {
      // defaultProps: {
      //   size: 'small',
      // },
      variants: [
        {
          props: { variant: 'standard' },
          style: ({ theme }) => ({
            '& .MuiInputBase-root::before, & .MuiInputBase-root::after': {
              content: 'none',
            },
            '& .MuiInputBase-root': {
              padding: '0.2em 0 0 0.3em',
              outlineWidth: 1,
              outlineColor: theme?.palette?.dividerSecondary,
            },
            '& .MuiInputBase-root:hover': {
              outlineStyle: 'dashed',
            },
            '& .MuiInputBase-root:has(:focus)': {
              outlineStyle: 'solid',
            },
          }),
        },
      ],
      styleOverrides: {
        root({
          ownerState,
          theme,
        }: {
          theme?: Theme;
          ownerState: TextFieldProps;
        }) {
          if (ownerState.variant === 'outlined') {
            return {
              '.MuiOutlinedInput-root:not(#a)': {
                outlineWidth: 1,
                outlineOffset: -5,
                outlineColor: theme?.palette?.dividerSecondary,
              },
              '.MuiOutlinedInput-notchedOutline, .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline':
                {
                  borderColor: theme?.palette?.divider,
                },
              '.MuiInputBase-root:hover.MuiOutlinedInput-root': {
                outlineStyle: 'dashed',
              },
              '.MuiInputBase-root.Mui-focused.MuiOutlinedInput-notchedOutline':
                {
                  borderWidth: 1,
                  borderColor: theme?.palette?.divider,
                },
              '.MuiInputBase-root.Mui-focused.MuiOutlinedInput-root': {
                outlineStyle: 'solid',
              },
            };
          }

          return {};
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        root: ({ theme }: { theme?: Theme }) => ({
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: theme?.palette?.divider,
          padding: '8px 16px',
          '.MuiStepLabel-iconContainer': {
            display: 'none',
          },
          '&:not(:has(.Mui-disabled)):not(.Mui-completed)': {
            outlineWidth: 1,
            outlineOffset: -4,
            outlineStyle: 'solid',
            outlineColor: theme?.palette?.dividerSecondary,
          },
        }),
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: ({ theme }: { theme?: Theme }) => ({
          '.MuiStepConnector-line': {
            borderColor: theme?.palette?.divider,
          },
        }),
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          alignItems: 'stretch',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
        LinkComponent: forwardRef<HTMLAnchorElement, LinkProps>(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          function FinsightLink({ color, href, ...rest }, ref) {
            return <Link ref={ref} to={href ?? ''} {...rest} />;
          },
        ),
      },
      styleOverrides: {
        root: ({ theme }: { theme?: Theme }) => ({
          '&:not(#a)': {
            outlineWidth: 1,
            outlineOffset: -4,
            outlineColor: theme?.palette?.dividerSecondary,
          },
          '&:hover': {
            outlineStyle: 'dashed',
          },
          '&.Mui-focusVisible, &:active': {
            outlineStyle: 'solid',
          },
        }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({}: { theme?: Theme }) => ({
          '&:not(#a)': {
            borderRadius: 0,
            maxHeight: 'none',
            height: '100%',
          },
        }),
      },
    },
    MuiList: {
      defaultProps: {
        // dense: true,
        disablePadding: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          borderWidth: 1,
          borderStyle: 'none',
          borderColor: theme?.palette?.divider,
        }),
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          margin: '4px 0 !important',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableFocusRipple: true,
        disableRipple: true,
        disableTouchRipple: true,
        variant: 'outlined',
      },
      styleOverrides: {
        root: {},
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
        variant: 'outlined',
      },
      styleOverrides: {
        root: ({ theme }: { theme?: Theme }) => ({
          '.MuiAutocomplete-option': {
            outlineWidth: 1,
            outlineOffset: -4,
            outlineColor: theme?.palette?.dividerSecondary,
          },
          '.MuiAutocomplete-option:hover': {
            outlineStyle: 'dashed',
          },
          '.MuiAutocomplete-option[aria-selected="true"]': {
            outlineStyle: 'solid',
          },
        }),
      },
    },
    MuiAlert: {
      styleOverrides: {
        root({ ownerState, theme }: { ownerState: AlertProps; theme?: Theme }) {
          const baseStyle: CSSInterpolation = {
            '&': {
              border: 0,
            },
          };

          if (ownerState.severity === 'warning') {
            return {
              '&': {
                ...baseStyle,
                backgroundColor: theme?.palette?.warning?.main,
                color: theme?.palette?.warning?.contrastText,
                '.MuiAlert-icon': {
                  color: theme?.palette?.warning?.contrastText,
                },
              },
            };
          }

          return baseStyle;
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({}: { theme?: Theme }) => ({
          '&.Mui-selected': {
            outlineStyle: 'solid',
          },
        }),
      },
    },
    MuiCard: {
      styleOverrides: {
        root: ({ theme }: { theme?: Theme }) => ({
          '&:focus-visible': {
            outlineWidth: 1,
            outlineOffset: -4,
            outlineStyle: 'solid',
            outlineColor: theme?.palette?.dividerSecondary,
          },
        }),
      },
    },
    MuiCheckbox: {
      defaultProps: {
        icon: <div className="MuiCheckbox-box" />,
        checkedIcon: <div className="MuiCheckbox-box" />,
      },
      styleOverrides: {
        root: ({ theme }: { theme?: Theme }) => ({
          '.MuiCheckbox-box': {
            height: 20,
            width: 20,
            padding: 3,
            borderWidth: 1,
            display: 'flex',
            borderStyle: 'solid',
            borderColor: theme?.palette?.divider,
            ':focus-visible': {
              outlineStyle: 'solid',
            },
          },
          'input:hover ~ .MuiCheckbox-box': {
            outlineWidth: 1,
            outlineOffset: -4,
            outlineStyle: 'dashed',
            outlineColor: theme?.palette?.dividerSecondary,
          },
          '&.Mui-checked .MuiCheckbox-box': {
            '&::after': {
              width: '100%',
              height: '100%',
              content: '""',
              background: theme?.palette?.dividerSecondary,
            },
          },
          outlineStyle: 'none !important',
        }),
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&:not(.Mui-expanded) ~ .MuiAccordion-root:not(.Mui-expanded)': {
            borderTopWidth: 0,
          },
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        TabIndicatorProps: {
          sx: {
            display: 'none',
          },
        },
      },
      styleOverrides: {
        root: ({ theme }) => ({
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: theme?.palette?.divider,
          '& ~ .MuiTabs-root': {
            borderTopWidth: 0,
          },
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-selected': {
            outlineStyle: 'solid',
          },
          '& ~ .MuiTab-root': {
            borderStyle: 'solid',
            borderColor: theme?.palette?.divider,
            borderWidth: 0,
            borderLeftWidth: 1,
            ':last-of-type': {
              borderRightWidth: 1,
            },
          },
        }),
      },
    },
  },
};

const lightTheme = createTheme({
  ...baseTheme,
});

const darkTheme = createTheme({
  ...baseTheme,
  palette: {
    mode: 'dark',
    error: { main: '#ff0000' },
    success: { main: '#00ff00' },
    primary: {
      ...cyan,
      main: cyan[600],
    },
    background: {
      default: common.black,
      paper: common.black,
      inverted: common.white,
    },
    divider: cyan[600],
    dividerSecondary: orange[500],
  },
  shape: {
    borderRadius: 0,
  },
});

export { darkTheme, lightTheme };
