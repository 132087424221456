import { Button, Divider, Stack, TextField, Typography } from '@mui/material';
import { useModal } from 'components/modal';
import React from 'react';
import { Commodity } from 'shared';

export default function useCommodityModal() {
  const { showModal } = useModal();
  return {
    onShowCommodityModal: (commodity: Commodity) => {
      showModal(
        commodity.name,
        <CommodityModal commodity={commodity} />,
        'Commodity',
      );
    },
  };
}

function CommodityModal({ commodity }: { commodity: Commodity }) {
  return (
    <Stack gap={2} width={600}>
      <Stack direction="row">
        <Stack flexGrow={1}>
          <Typography variant="caption">Inventory</Typography>
          <Typography variant="h6">{commodity.quantity}</Typography>
        </Stack>
        <Stack flexGrow={1}>
          <Typography variant="caption">Total</Typography>
          <Typography variant="h6">
            ${commodity.quantity * commodity.price}
          </Typography>
        </Stack>
      </Stack>

      <TextField fullWidth label="Name" value={commodity.name} />
      <Stack direction="row" gap={2}>
        <TextField fullWidth label="Brand" value={commodity.brand} />
        <TextField fullWidth label="Price" value={commodity.price} />
      </Stack>
      <TextField label="Unit" value={commodity.unit} />
      <Stack direction="row" alignItems="center" gap={3}>
        <img
          src={commodity.imageUrl ?? ''}
          alt={commodity.name}
          width={150}
          height={150}
        />
        <Stack gap={1}>
          <Typography variant="h6">Image.png</Typography>
          <Typography>This image is not stored on finsight servers.</Typography>
          <Button>Replace Image</Button>
        </Stack>
      </Stack>
      <Divider />
      <Typography variant="h5">Transactions</Typography>
      {commodity.transactions.length ? 'Transactions!' : 'No transactions'}
    </Stack>
  );
}
