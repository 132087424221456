import {
  Add,
  AssignmentOutlined,
  Circle,
  CircleOutlined,
  DeleteOutline,
  Inventory2Outlined,
  PaidOutlined,
  ShoppingCartOutlined,
} from '@mui/icons-material';
import {
  Button,
  Chip,
  Container,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import {
  EndpointUrl,
  useCommodities,
  useEntity,
  useSubProjects,
  useSubTasks,
  useTransactions,
  useUpdateEntity,
} from 'api/projects/provider';
import useCommodityModal from 'components/commodityModal';
import useCreateCommodity from 'components/createCommodity';
import useCreateProject from 'components/createProject';
import useCreateTask from 'components/createTask';
import useCreateTransaction from 'components/createTransaction';
import EditableTextField from 'components/editableTextField';
import ProjectView, { PriorityIcons } from 'components/projectView';
import { useTransactionModal } from 'components/transactionModal';
import useIsMobile from 'hooks/useIsMobile';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Status, Statuses, StatusSchema, Task } from 'shared';

type Task_V = Omit<Task, 'deadline'> & { deadline: string };

export function View({ type }: { type: 'project' | 'task' }) {
  const isMobile = useIsMobile();
  const { id } = useParams();
  const refObject = id
    ? {
        id,
        type,
      }
    : undefined;

  const entity = useEntity(refObject);
  const subTasksRaw = useSubTasks(refObject, true);
  const subProjects = useSubProjects(refObject, true);
  const commodities = useCommodities(refObject, true);
  const transactions = useTransactions(refObject, true);

  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const [tab, setTab] = useState<'transactions' | 'tasks' | 'commodities'>(
    'tasks',
  );

  const createProject = useCreateProject(
    () => subProjects.mutate(),
    entity.data,
  );

  const updateEntity = useUpdateEntity(refObject);
  const createTask = useCreateTask(() => subTasksRaw.mutate(), entity.data);
  const createCommodity = useCreateCommodity(
    () => commodities.mutate(),
    entity.data,
  );
  const createTransaction = useCreateTransaction(
    () => transactions.mutate(),
    entity.data,
  );

  const navigate = useNavigate();

  const { onShowTransactionModal } = useTransactionModal();
  const { onShowCommodityModal } = useCommodityModal();

  // const deadline = useMemo(
  //   () =>
  //     entity.data?.deadline
  //       ? moment(entity.data?.deadline).format('MMM Do, YYYY h:mm a')
  //       : null,
  //   [entity.data?.deadline],
  // );

  const tasks = useMemo(() => {
    const tasksObj: Record<Status, Task_V[]> = {
      planning: [],
      ready: [],
      active: [],
      completed: [],
      cancelled: [],
    };

    for (const task of subTasksRaw.data ?? []) {
      const newTask: Task_V = {
        ...task,
        deadline: task.deadline
          ? moment(task.deadline).format('MMM Do, YYYY h:mm a')
          : '',
      };

      tasksObj[task.status].push(newTask);
    }

    return tasksObj;
  }, [subTasksRaw]);

  return (
    <>
      <Container maxWidth="xl" sx={{ py: 5 }}>
        {entity.isLoading ? (
          'Loading'
        ) : entity.error ? (
          `${entity.error}`
        ) : entity ? (
          <Stack gap={2}>
            <Stack
              gap={1}
              alignItems="center"
              direction="row"
              justifyContent="space-between"
            >
              <EditableTextField
                value={entity.data?.name}
                onChange={async (v) => {
                  await updateEntity({
                    name: v,
                  });
                  entity.mutate();
                }}
                inputProps={{
                  sx: {
                    fontSize: '2.5rem',
                    lineHeight: '1em',
                    py: 1,
                  },
                }}
              />
              <Stack
                justifyContent="flex-start"
                alignItems="center"
                direction="row"
                gap={1}
              >
                <Button
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  startIcon={<Add />}
                >
                  New
                </Button>
                <Button
                  onClick={async () => {
                    if (entity.data && confirm('Are you sure?')) {
                      if (entity.data.type === 'project') {
                        await fetch(
                          `${EndpointUrl}/project/${entity.data?._id}`,
                          {
                            method: 'DELETE',
                          },
                        );
                      } else {
                        await fetch(`${EndpointUrl}/task/${entity.data?._id}`, {
                          method: 'DELETE',
                        });
                      }

                      if (entity.data.parent) {
                        navigate(
                          `/${entity.data.parent.type}/${entity.data.parent.id}`,
                        );
                      } else {
                        navigate('/');
                      }
                    }
                  }}
                  startIcon={<DeleteOutline />}
                  color="error"
                >
                  Delete
                </Button>
              </Stack>
            </Stack>
            <Stack
              gap={1}
              justifyContent="space-between"
              direction={isMobile ? 'column' : 'row'}
              alignItems={isMobile ? 'stretch' : 'flex-start'}
            >
              <Stack gap={1} alignItems="flex-start" flexGrow={1}>
                <Stack gap={1} direction="row" alignItems="center">
                  <TextField
                    select
                    value={entity.data?.priority.level}
                    onChange={async (e) => {
                      await updateEntity({
                        priority: {
                          level: Number(e.target.value),
                          prerequisites:
                            entity.data?.priority.prerequisites ?? [],
                        },
                      });
                      entity.mutate();
                    }}
                  >
                    {PriorityIcons.toReversed().map((priority, i) => (
                      <MenuItem value={5 - i} key={i}>
                        <Stack gap={2} direction="row" alignItems="center">
                          {priority.icon}
                          {priority.label}
                        </Stack>
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    select
                    value={entity.data?.status}
                    label="Status"
                    onChange={async (e) => {
                      try {
                        const newStatus = StatusSchema.parse(e.target.value);
                        await updateEntity({
                          status: newStatus,
                        });
                        entity.mutate();
                      } catch {}
                    }}
                  >
                    {Statuses.map((status) => (
                      <MenuItem key={status.value} value={status.value}>
                        {status.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Stack>
                <EditableTextField
                  value={entity.data?.description}
                  onChange={async (v) => {
                    await updateEntity({
                      description: v,
                    });
                    entity.mutate();
                  }}
                />
              </Stack>
              <Stack gap={2}>
                <Typography variant="h5">Budget</Typography>
                <Stack gap={2} direction={isMobile ? 'row' : 'column'}>
                  <EditableTextField
                    label="Estimate"
                    value={entity.data?.budget?.estimate}
                    InputProps={{
                      startAdornment: '$',
                    }}
                    onChange={async (v) => {
                      if (isNaN(+v)) throw new Error();
                      await updateEntity({
                        budget: {
                          estimate: +v,
                          limit: entity.data?.budget?.limit ?? 0,
                          total: entity.data?.budget?.total ?? 0,
                        },
                      });
                      entity.mutate();
                    }}
                  />
                  <EditableTextField
                    label="Limit"
                    value={entity.data?.budget?.limit}
                    InputProps={{
                      startAdornment: '$',
                    }}
                    onChange={async (v) => {
                      if (isNaN(+v)) throw new Error();
                      await updateEntity({
                        budget: {
                          limit: +v,
                          estimate: entity.data?.budget?.estimate ?? 0,
                          total: entity.data?.budget?.total ?? 0,
                        },
                      });
                      entity.mutate();
                    }}
                  />
                  <Stack flex="1 1 100%">
                    <Typography variant="caption" color="text.secondary">
                      Total
                    </Typography>
                    <Typography>${entity.data?.budget?.total}</Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            {subProjects.data && (
              <ProjectView
                projects={subProjects.data}
                refresh={() => subProjects.mutate()}
              />
            )}
            <Tabs value={tab} onChange={(_, v) => setTab(v)}>
              <Tab value="tasks" label="Tasks" />
              <Tab value="transactions" label="Transactions" />
              <Tab value="commodities" label="Commodities" />
            </Tabs>
            {tab === 'transactions' && (
              <Stack>
                <Typography variant="h5">Transactions</Typography>
                <List>
                  {transactions.data?.map((transaction, i) => [
                    ...(i !== 0 ? [<Divider key={i + '-divider'} />] : []),
                    <ListItemButton
                      key={transaction._id}
                      onClick={() => onShowTransactionModal(transaction)}
                    >
                      <Stack gap={0.5}>
                        <Stack direction="row" gap={1}>
                          <Chip label={transaction.parentPaths.join(' > ')} />
                          {transaction.name}
                        </Stack>
                        <Stack gap={1} direction="row" alignItems="center">
                          {/* {transaction.merchant && (
                            <>
                              {transaction.merchant.logoUrl && (
                                <img
                                  src={transaction.merchant.logoUrl}
                                  width={16}
                                  height={16}
                                />
                              )}
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                {transaction.merchant.name}
                              </Typography>
                              {'-'}
                            </>
                          )} */}
                          <Typography variant="body2" color="text.secondary">
                            {transaction.created
                              ? moment(transaction.created)?.format(
                                  'MMM Do, YYYY h:mm a',
                                )
                              : null}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack
                        flexGrow={1}
                        justifyContent="flex-end"
                        direction="row"
                      >
                        <Typography
                          color={
                            transaction.credit ? 'success.main' : 'error.main'
                          }
                        >
                          {transaction.credit ? '' : '-'}$
                          {transaction.amount.toFixed(2)}
                        </Typography>
                      </Stack>
                    </ListItemButton>,
                  ])}
                </List>
              </Stack>
            )}
            {tab === 'tasks' && (
              <>
                <Typography variant="h4">Tasks</Typography>
                <TasksView title="Active" tasks={tasks.active} />
                <TasksView title="Ready" tasks={tasks.ready} />
                <TasksView title="Planning" tasks={tasks.planning} />
                <TasksView title="Completed" tasks={tasks.completed} />
                <TasksView title="Cancelled" tasks={tasks.cancelled} />
              </>
            )}
            {tab === 'commodities' && (
              <Stack>
                <Typography variant="h5">Commodities</Typography>
                <List>
                  {commodities.data?.map((commodity, i) => [
                    ...(i !== 0 ? [<Divider key={i} />] : []),
                    <ListItemButton
                      key={commodity._id}
                      onClick={() => onShowCommodityModal(commodity)}
                    >
                      {commodity.imageUrl && (
                        <img
                          src={commodity.imageUrl}
                          width={48}
                          height={48}
                          style={{
                            marginRight: 16,
                          }}
                        />
                      )}
                      <Stack flexGrow={1}>
                        <Typography variant="h6">{commodity.name}</Typography>
                        <Typography variant="body2" color="text.secondary">
                          {commodity.brand}
                        </Typography>
                      </Stack>
                      {/* <Chip label={task.parentPaths.join(' > ')} />
                      <Stack gap={0.5}>
                        <Stack direction="row" gap={1}>
                          <Chip label={commodity.parentPaths.join(' > ')} />
                          {commodity.name}
                        </Stack>
                        <Stack gap={1} direction="row" alignItems="center">
                          {/* {commodity.merchant && (
                            <>
                              {commodity.merchant.logoUrl && (
                                <img
                                  src={commodity.merchant.logoUrl}
                                  width={16}
                                  height={16}
                                />
                              )}
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                {commodity.merchant.name}
                              </Typography>
                              {'-'}
                            </>
                          )} *d/}
                          <Typography variant="body2" color="text.secondary">
                            {commodity.brand}
                          </Typography>
                        </Stack>
                      </Stack> */}
                      <Stack flexGrow={1} alignItems="flex-end">
                        <Stack gap={0.5} direction="row">
                          <Typography variant="body2">
                            ${commodity.price}
                          </Typography>
                          {commodity.unit && (
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              noWrap
                            >
                              / {commodity.unit}
                            </Typography>
                          )}
                        </Stack>
                        <Typography variant="body2" color="text.secondary">
                          x{commodity.quantity}
                        </Typography>
                      </Stack>
                    </ListItemButton>,
                  ])}
                </List>
              </Stack>
            )}
          </Stack>
        ) : (
          <Typography>Project not found</Typography>
        )}
      </Container>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        {createProject && (
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              createProject();
            }}
          >
            <ListItemIcon>
              <Inventory2Outlined />
            </ListItemIcon>
            New Sub-Project
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            createTask();
          }}
        >
          <ListItemIcon>
            <AssignmentOutlined />
          </ListItemIcon>
          New Task
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            createTransaction?.();
          }}
        >
          <ListItemIcon>
            <PaidOutlined />
          </ListItemIcon>
          New Transaction
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            createCommodity?.();
          }}
        >
          <ListItemIcon>
            <ShoppingCartOutlined />
          </ListItemIcon>
          New Commodity
        </MenuItem>
      </Menu>
    </>
  );
}

function TasksView({ title, tasks }: { title: string; tasks: Task_V[] }) {
  const navigate = useNavigate();

  if (!tasks.length) return null;

  let Icon: React.ReactNode | null = null;

  switch (title) {
    case 'Planning':
      Icon = <CircleOutlined fontSize="small" color="info" />;
      break;
    case 'Ready':
      Icon = <CircleOutlined fontSize="small" color="warning" />;
      break;
    case 'Active':
      Icon = <Circle fontSize="small" color="warning" />;
      break;
    case 'Completed':
      Icon = <Circle fontSize="small" color="success" />;
      break;
    case 'Cancelled':
      Icon = <Circle fontSize="small" color="error" />;
      break;
  }

  return (
    <Stack>
      <Typography variant="h5">{title}</Typography>
      <List>
        {tasks.map((task, i) => [
          ...(i !== 0 ? [<Divider key={i} />] : []),
          <ListItemButton
            draggable={true}
            key={task._id}
            onClick={() => navigate(`/task/${task._id}`)}
            onDragStart={(e) => {
              const canvasElem = document.createElement('canvas');
              canvasElem.width = 200;
              canvasElem.height = 50;
              const ctx = canvasElem.getContext('2d');
              if (ctx) {
                ctx.fillStyle = 'black';
                ctx.roundRect(0, 0, 200, 50, 10);
                ctx.strokeStyle = 'cyan';
                ctx.fill();
                ctx.stroke();

                ctx.font = '20px Bahnschrift';
                ctx.fillStyle = 'white';
                ctx.fillText(task.name, 25, 30);
              }
              const img = new Image();
              img.src = canvasElem.toDataURL();
              e.dataTransfer.setDragImage(img, 0, 0);
              e.dataTransfer.setData(
                'text/plain/finsight.entity',
                JSON.stringify({
                  id: task._id,
                  type: 'task',
                }),
              );
              e.dataTransfer.effectAllowed = 'move';
            }}
          >
            {Icon !== null && <ListItemIcon>{Icon}</ListItemIcon>}
            <Stack flexGrow={1}>
              <Typography variant="h6">{task.name}</Typography>
              <Typography variant="body2" color="text.secondary">
                {task.description.slice(0, 100)}...
              </Typography>
            </Stack>
            <Chip label={task.parentPaths.join(' > ')} />
            {/* <ListItemText
              primary={'$' + (task.budget?.estimate ?? 0)}
              sx={{ textAlign: 'right' }}
            /> */}
          </ListItemButton>,
        ])}
      </List>
    </Stack>
  );
}
