import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { LicenseInfo } from '@mui/x-license';
import { ProjectsProvider } from 'api/projects/provider';
import Header from 'components/header';
import ModalProvider from 'components/modal';
import NotificationProvider from 'components/notify';
import DashboardPage from 'pages/dashboard';
import EntityPage from 'pages/entity';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { darkTheme } from 'theme';

LicenseInfo.setLicenseKey(
  '05a2ce020d90053b6547efbd692fa010Tz1NaWNyb2FydCxFPTI3MjA5MjA2ODUzMjUsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y',
);

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <NotificationProvider>
          <ProjectsProvider>
            <ModalProvider>
              <Header />
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<DashboardPage />} />
                  <Route
                    path="/project/:id"
                    element={<EntityPage type="project" />}
                  />
                  <Route
                    path="/task/:id"
                    element={<EntityPage type="task" />}
                  />
                </Routes>
              </BrowserRouter>
            </ModalProvider>
          </ProjectsProvider>
        </NotificationProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
