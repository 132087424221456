import {
  Chip,
  Divider,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import { useCommodities } from 'api/projects/provider';
import { useModal } from 'components/modal';
import moment from 'moment';
import React, { useMemo } from 'react';
import { Transaction } from 'shared';

export function useTransactionModal() {
  const { showModal } = useModal();
  return {
    onShowTransactionModal: (transaction: Transaction) => {
      showModal(
        transaction.name,
        <TransactionModal transaction={transaction} />,
        'Transaction',
      );
    },
  };
}

const styles = {
  pending: 'warning',
  paid: 'success',
  refunded: 'error',
} as const;

export function TransactionModal({
  transaction,
}: {
  transaction: Transaction;
}) {
  const commodities = useCommodities({
    type: 'transaction',
    id: transaction._id,
  });

  const date = useMemo(
    () => (transaction.created ? moment(transaction.created) : null),
    [transaction.created],
  );

  const subtotal = useMemo(
    () => commodities.data?.reduce((acc, c) => acc + c.price * c.quantity, 0),
    [commodities.data],
  );

  return (
    <Stack minHeight={0}>
      <Stack gap={2} alignItems="center" direction="row">
        <Chip
          size="medium"
          label={transaction.status}
          color={styles[transaction.status]}
          sx={{ textTransform: 'uppercase' }}
        />
        <Typography>{date?.format('MMM Do, YYYY [at] h:mm a')}</Typography>
      </Stack>
      {/* {!!transaction.merchant && (
        <>
          <Divider sx={{ my: '16px !important' }} />
          <MerchantCard merchant={transaction.merchant} />
        </>
      )} */}
      {!!commodities.data && (
        <>
          <Divider sx={{ my: '16px !important' }}>
            Items in this transaction
          </Divider>
          <List sx={{ overflowY: 'auto' }}>
            {commodities.data.map((commodity, index) => (
              <ListItem key={index}>
                {!!commodity.imageUrl && (
                  <img src={commodity.imageUrl} width={50} height={50} />
                )}
                <Typography ml={2} mr={1} minWidth={30}>
                  {commodity.quantity}x
                </Typography>
                <Stack>
                  <Typography>{commodity.name}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {commodity.price} / each
                  </Typography>
                </Stack>
                <Divider
                  sx={{
                    flexGrow: 1,
                    minWidth: 50,
                    mx: '16px !important',
                    borderStyle: 'dashed',
                  }}
                />
                <Typography>{commodity.price * commodity.quantity}</Typography>
              </ListItem>
            ))}
          </List>
          <Typography textAlign="right" mt={1}>
            {commodities.data.reduce((acc, x) => (acc += x.quantity), 0)}
            &nbsp; items
          </Typography>
        </>
      )}
      <Divider sx={{ my: '16px !important' }} />
      <Stack
        gap={1}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h5">Subtotal</Typography>
        <Typography variant="h5">
          ${subtotal ? subtotal.toFixed(2) : '--.--'}
        </Typography>
      </Stack>
      <Stack
        gap={1}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography fontWeight={400} variant="h4">
          Total
        </Typography>
        <Typography fontWeight={400} variant="h4">
          ${transaction.amount.toFixed(2)}
        </Typography>
      </Stack>
    </Stack>
  );
}
