import styled from '@emotion/styled';
import {
  NumberInputProps,
  Unstable_NumberInput as BaseNumberInput,
} from '@mui/base';
import { Theme, Typography } from '@mui/material';
import React from 'react';

function NumberInput(props: NumberInputProps) {
  const { startAdornment, endAdornment, ...rest } = props;
  return (
    <BaseNumberInput
      {...rest}
      startAdornment={
        startAdornment ? (
          <Typography className="startAdornment">{startAdornment}</Typography>
        ) : undefined
      }
      endAdornment={
        endAdornment ? (
          <Typography className="endAdornment">{endAdornment}</Typography>
        ) : undefined
      }
      slotProps={{
        incrementButton: {
          children: '+',
        },
        decrementButton: {
          children: '-',
        },
      }}
    />
  );
}

export default styled(NumberInput)(
  ({
    startAdornment,
    endAdornment,
    theme,
  }: NumberInputProps & { theme?: Theme }) =>
    `
      & {
        display: grid; 
        grid-template-columns: ${startAdornment ? '2em' : ''} 1fr 1.5em ${endAdornment ? '2em' : ''};
        grid-template-rows: 1fr 1fr;
        flex-shrink: 0;
        border: 1px solid ${theme?.palette.divider};
        color: ${theme?.palette.text.primary};

        button {
          color: inherit;
          border: none; 
          border-left: 1px solid ${theme?.palette.divider};
          background: none;
          outline-color: orange;
          outline-offset: -4px;
          outline-width: 1px;
          grid-column: ${startAdornment ? 3 : 2};
        }

        button:first-child {
          border-top: 1px solid ${theme?.palette.divider};
        }
        
        button:hover {
          outline-style: dashed;
        }

        input {
          min-width: 0;
          color: inherit;
          grid-row: 1 / 3;
          padding: 0 8px;
          grid-column: ${startAdornment ? 2 : 1};
          border: none;
          background: none;
          
          outline-color: orange;
          outline-offset: -4px;
          outline-width: 1px;
        }

        input:hover {
          outline-style: dashed;
        }

        input:focus {
          outline-style: solid;
        }

        .base-NumberInput-incrementButton {
          grid-row: 1;
        }

        .startAdornment, .endAdornment {
          display: flex;
          align-items: center;
          justify-content: center;
        }
          
        .startAdornment {
          grid-column: 1;
          grid-row: 1 / 3;
          border-right: 1px solid ${theme?.palette.divider};
        }
            
        .endAdornment {
          grid-column: 4;
          grid-row: 1 / 3;
          border-left: 1px solid ${theme?.palette.divider};
        }
      }
    `,
);
