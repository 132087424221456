import { Button, Stack, TextField, Typography } from '@mui/material';
import { EndpointUrl } from 'api/projects/provider';
import { useCurrentModal, useModal } from 'components/modal';
import React, { useState } from 'react';
import { Commodity, CommoditySchema, Project, Task } from 'shared';

export default function useCreateCommodity(
  refresh: () => void,
  parent?: Project | Task,
) {
  const { showModal } = useModal();

  if (parent) {
    return () => {
      showModal(
        'New Commodity',
        <CreateCommodityModal parent={parent} refresh={refresh} />,
        parent.name,
      );
    };
  }

  return null;
}

function CreateCommodityModal({
  parent,
  refresh,
}: {
  parent: Project | Task;
  refresh: () => void;
}) {
  const ctx = useCurrentModal();

  // new Project().deadline;

  const [commodityTemplate, setCommodityTemplate] = useState<Commodity>({
    _id: '',
    description: '',
    name: '',
    priority: {
      level: 2,
      prerequisites: [],
    },
    created: new Date(),
    parent: {
      id: parent._id,
      type: parent.type,
    },
    parentPaths: [],
    tasks: [],
    transactions: [],
    type: 'commodity',
    brand: '',
    price: 0,
    quantity: 0,
    unit: 'unit',
    imageUrl: null,
    children: [],
  });

  return (
    <Stack gap={2}>
      <Stack gap={2} flexGrow={1} minWidth={800}>
        <Typography variant="h6">Basic Info</Typography>
        <TextField
          required
          error={!commodityTemplate.name.trim().length}
          label="Name"
          value={commodityTemplate.name}
          onChange={(e) =>
            setCommodityTemplate((prev) => ({
              ...prev,
              name: e.target.value,
            }))
          }
        />
        <TextField
          required
          error={!commodityTemplate.brand.trim().length}
          label="Brand"
          value={commodityTemplate.brand}
          onChange={(e) =>
            setCommodityTemplate((prev) => ({
              ...prev,
              brand: e.target.value,
            }))
          }
        />
        <TextField
          required
          label="Price"
          value={commodityTemplate.price}
          onChange={(e) =>
            setCommodityTemplate((prev) => ({
              ...prev,
              price: +e.target.value,
            }))
          }
        />
        <TextField
          label="Description"
          multiline
          minRows={10}
          required
          error={!commodityTemplate.description.trim().length}
          value={commodityTemplate.description}
          onChange={(e) =>
            setCommodityTemplate((prev) => ({
              ...prev,
              description: e.target.value,
            }))
          }
        />
      </Stack>
      <Stack gap={2} direction="row" justifyContent="flex-end">
        <Button color="error" onClick={() => ctx?.closeModal()}>
          Cancel
        </Button>
        <Button
          onClick={async () => {
            try {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { _id, created, ...rest } = commodityTemplate;
              const projectPayload = CommoditySchema.parse({
                ...rest,
                created: new Date(),
              });

              await fetch(`${EndpointUrl}/commodity`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(projectPayload),
              });

              ctx?.closeModal();

              setTimeout(() => {
                refresh();
              }, 1000);
            } catch (e) {
              console.dir(e);
            }
          }}
        >
          Create Project
        </Button>
      </Stack>
    </Stack>
  );
}
