import React, { createContext, useEffect, useReducer, useRef } from 'react';
import {
  CommoditySchema,
  Project as NewProject,
  ProjectRef,
  ProjectSchema,
  Task as NewTask,
  TaskRef,
  TaskSchema,
  TransactionRef,
  TransactionSchema,
} from 'shared';
import useSWR from 'swr';

import { Entity, Merchant, Project, Task, Transaction } from './create';
import { GlobalStore } from './globalStore';

export type Stateful<T> = React.Dispatch<React.SetStateAction<T>>;

export type ControllerState<T> = {
  current: T;
  onChange: Stateful<T>;
};

export const EndpointUrl = process.env.REACT_APP_SERVER_URL;

type ProjectsProviderType = {
  entities: Entity[];
  tasks: Task[];
  projects: Project[];
  rootProjects: Project[];
  merchants: Merchant[];
  transactions: Transaction[];
};

export const ProjectsContext = createContext({} as ProjectsProviderType);

export class WebAddress extends URL {
  static defaultScheme = 'https';
  static defaultPath = '/';
  static defaultSubdomain = 'www';
  static defaultDomainSuffix = 'com';

  constructor(_urlPart: string) {
    // possibles:
    // 1. domain
    // 2. domain.tld
    // 3. www.domain.tld
    // 4. https://www.domain.tld
    // 6. https://www.domain.tld/search

    let urlPart = _urlPart;
    const urlObject = {
      schema: WebAddress.defaultScheme,
      subdomain: WebAddress.defaultSubdomain,
      domain: '',
      domainSuffix: WebAddress.defaultDomainSuffix,
      path: WebAddress.defaultPath,
    };

    const schemaParts = urlPart.split('://');
    if (schemaParts.length > 1) {
      urlObject.schema = schemaParts[0];
      urlPart = schemaParts[1];
    }

    const pathParts = urlPart.split('/');
    if (pathParts.length > 1) {
      urlObject.path = '/' + pathParts.slice(1).join('/');
      urlPart = pathParts[0];
    }

    const domainParts = urlPart.split('.');
    if (domainParts.length > 1) {
      const domainSuffix = domainParts.pop();
      const domain = domainParts.pop();
      if (domain) {
        urlObject.domain = domain;
      }
      if (domainSuffix) {
        urlObject.domainSuffix = domainSuffix;
      }
      if (domainParts.length) {
        urlObject.subdomain = domainParts.join('.');
      }
    } else {
      urlObject.domain = domainParts[0];
    }

    const fullUrl = `${urlObject.schema}://${urlObject.subdomain}.${urlObject.domain}.${urlObject.domainSuffix}${urlObject.path}`;

    super(fullUrl);
  }
}

export function ProjectsProvider(props: React.PropsWithChildren) {
  console.log('update');
  const [, update] = useReducer((x) => x + 1, 0);
  const firstRun = useRef(true);

  useEffect(() => {
    GlobalStore.onEvent('topLevelUpdate', 'ProjectsProvider', update);
    if (firstRun.current) {
      firstRun.current = false;
      GlobalStore.clear();
      const savedDataRaw = window.localStorage.getItem('context');
      if (savedDataRaw) {
        try {
          const savedData = JSON.parse(savedDataRaw);
          GlobalStore.import(savedData);
        } catch {}
      }
    }
  }, []);

  return (
    <ProjectsContext.Provider
      value={{
        entities: GlobalStore.entities,
        tasks: GlobalStore.tasks,
        projects: GlobalStore.projects,
        rootProjects: GlobalStore.rootProjects,
        merchants: GlobalStore.merchants,
        transactions: [],
      }}
    >
      {props.children}
    </ProjectsContext.Provider>
  );
}

export function useEntity(refObject?: ProjectRef | TaskRef) {
  return useSWR(
    !!refObject
      ? (`${EndpointUrl}/${refObject.type}/${refObject.id}` as string)
      : null,
    async (url) => {
      const response = await fetch(url);
      const data = await response.json();
      try {
        if (refObject?.type === 'project') {
          const project = ProjectSchema.parse(data);
          return project;
        } else {
          const task = TaskSchema.parse(data);
          return task;
        }
      } catch (e) {
        console.dir(e);
      }
    },
  );
}

export function useUpdateEntity(refObject?: ProjectRef | TaskRef) {
  return async (data: Partial<NewProject | NewTask>) => {
    await fetch(`${EndpointUrl}/${refObject?.type}/${refObject?.id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  };
}

export function useSubTasks(parentRef?: ProjectRef | TaskRef, nested = false) {
  return useSWR(
    !!parentRef
      ? (`${EndpointUrl}/${parentRef.type}/${parentRef.id}/tasks?nested=${nested}` as string)
      : null,
    async (url) => {
      const response = await fetch(url);
      const data = await response.json();
      try {
        const tasks = TaskSchema.array().parse(data);
        return tasks;
      } catch (e) {
        console.dir(e);
      }
    },
  );
}

export function useCommodities(
  parentRef?: TransactionRef | ProjectRef | TaskRef,
  nested = false,
) {
  return useSWR(
    !!parentRef
      ? (`${EndpointUrl}/${parentRef.type}/${parentRef.id}/commodities?nested=${nested}` as string)
      : null,
    async (url) => {
      const response = await fetch(url);
      const data = await response.json();
      try {
        const commodities = CommoditySchema.array().parse(data);
        return commodities;
      } catch (e) {
        console.dir(e);
      }
    },
  );
}

export function useTransactions(
  parentRef?: ProjectRef | TaskRef,
  nested = false,
) {
  return useSWR(
    !!parentRef
      ? (`${EndpointUrl}/${parentRef.type}/${parentRef.id}/transactions?nested=${nested}` as string)
      : null,
    async (url) => {
      const response = await fetch(url);
      const data = await response.json();
      try {
        const transactions = TransactionSchema.array().parse(data);
        return transactions;
      } catch (e) {
        console.dir(e);
      }
    },
  );
}

export function useSubProjects(
  parentRef?: ProjectRef | TaskRef,
  nested = false,
) {
  return useSWR(
    parentRef?.type === 'project'
      ? (`${EndpointUrl}/${parentRef.type}/${parentRef.id}/projects?nested=${nested}` as string)
      : null,
    async (url) => {
      const response = await fetch(url);
      const data = await response.json();
      try {
        const projects = ProjectSchema.array().parse(data);
        return projects;
      } catch (e) {
        console.dir(e);
      }
    },
  );
}

export function useRootProjects() {
  return useSWR(`${EndpointUrl}/project/root-projects`, async (url) => {
    const response = await fetch(url);
    const data = await response.json();
    try {
      const projects = ProjectSchema.array().parse(data);
      return projects;
    } catch (e) {
      console.dir(e);
    }
  });
}
