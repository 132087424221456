import { Stack, Typography } from '@mui/material';
import { Merchant } from 'api/projects/create';
import React from 'react';

export default function MerchantCard({ merchant }: { merchant: Merchant }) {
  return (
    <Stack gap={2} direction="row" alignItems="center">
      {merchant.logoUrl && (
        <img
          src={merchant.logoUrl}
          alt={merchant.name}
          width="48"
          height="48"
        />
      )}
      <Stack>
        <Typography variant="h6">{merchant.name}</Typography>
        <Typography>{merchant.description}</Typography>
      </Stack>
    </Stack>
  );
}
