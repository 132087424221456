import { AccountCircleOutlined, Menu } from '@mui/icons-material';
import {
  AppBar,
  Button,
  Container,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import useIsMobile from 'hooks/useIsMobile';
import React, { useState } from 'react';

export default function Header() {
  const isMobile = useIsMobile();

  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <>
      <AppBar position="static">
        <Toolbar sx={{ minHeight: '48px !important' }}>
          <Container maxWidth="lg">
            <Stack
              height="100%"
              flexGrow={1}
              direction="row"
              alignItems="stretch"
              justifyContent="space-between"
            >
              <Stack direction="row">
                <Button>
                  <Typography
                    variant="h5"
                    fontWeight={500}
                    color="text.primary"
                    textTransform="uppercase"
                  >
                    Finsight
                  </Typography>
                  <Typography color="text.primary" ml={1}>
                    by Microart
                  </Typography>
                </Button>
              </Stack>
              {isMobile ? (
                <Stack>
                  <IconButton onClick={() => setDrawerOpen(true)}>
                    <Menu />
                  </IconButton>
                </Stack>
              ) : (
                <>
                  <TextField
                    placeholder="Search"
                    sx={{
                      flex: '0 1 400px',
                      '.MuiInputBase-root': {
                        height: '100%',
                      },
                      input: {
                        height: '100%',
                        boxSizing: 'border-box',
                      },
                    }}
                  />
                  <Stack direction="row" alignItems="center">
                    <IconButton>
                      <AccountCircleOutlined />
                    </IconButton>
                  </Stack>
                </>
              )}
            </Stack>
          </Container>
        </Toolbar>
      </AppBar>
      <Drawer
        open={drawerOpen}
        anchor="right"
        onClose={() => setDrawerOpen(false)}
      >
        <List
          sx={{
            minWidth: 200,
          }}
        >
          <ListItemButton>
            <ListItemText primary="Hello!" />
          </ListItemButton>
        </List>
      </Drawer>
    </>
  );
}
