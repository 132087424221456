"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProjectSchema = exports.ProjectTaskSchema = exports.Statuses = exports.StatusSchema = void 0;
var zod_1 = require("zod");
var entity_1 = require("./entity");
exports.StatusSchema = zod_1.z.enum([
    'planning',
    'ready',
    'active',
    'completed',
    'cancelled',
]);
exports.Statuses = [
    { value: 'planning', label: 'Planning' },
    { value: 'ready', label: 'Ready' },
    { value: 'active', label: 'Active' },
    { value: 'completed', label: 'Completed' },
    { value: 'cancelled', label: 'Cancelled' },
];
var BudgetSchema = zod_1.z.object({
    estimate: zod_1.z.number(),
    limit: zod_1.z.number().default(0),
    total: zod_1.z.number().default(0),
});
exports.ProjectTaskSchema = entity_1.EntitySchema.extend({
    type: zod_1.z.enum(['project', 'task']),
    deadline: zod_1.z.union([entity_1.DateSchema, zod_1.z.null()]).optional(),
    status: exports.StatusSchema.default('planning'),
    budget: zod_1.z.union([BudgetSchema, zod_1.z.null()]).default({ estimate: 0 }),
    commodities: zod_1.z.array(entity_1.CommodityRefSchema).default([]),
});
exports.ProjectSchema = exports.ProjectTaskSchema.extend({
    type: zod_1.z.literal('project'),
    projects: zod_1.z.array(entity_1.ProjectRefSchema).default([]),
});
