import { Button, Container, Divider, Stack, Typography } from '@mui/material';
import { ProjectsContext, useRootProjects } from 'api/projects/provider';
import useCreateProject from 'components/createProject';
import MerchantCard from 'components/merchant';
import ProjectView from 'components/projectView';
import React, { useContext } from 'react';
export function View() {
  const { merchants } = useContext(ProjectsContext);

  const rootProjects = useRootProjects();
  const createProject = useCreateProject(() => rootProjects.mutate());

  return (
    <Container maxWidth="xl">
      <Stack
        my={2}
        alignItems="center"
        direction="row"
        justifyContent="space-between"
      >
        <Typography variant="h4">Projects</Typography>
        <Button onClick={() => createProject?.()}>New</Button>
      </Stack>
      <ProjectView
        hideTitle
        projects={rootProjects.data ?? []}
        refresh={() => rootProjects.mutate()}
      />
      <Divider />
      <Typography variant="h4">Merchants</Typography>
      {merchants.map((merchant) => (
        <MerchantCard key={merchant.id} merchant={merchant} />
      ))}
    </Container>
  );
}
