import { RemoveCircleOutline } from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers-pro';
import { EndpointUrl, useCommodities } from 'api/projects/provider';
import { useCurrentModal, useModal } from 'components/modal';
import NumberInput from 'components/NumberInput';
import { Moment } from 'moment';
import React, { useMemo, useState } from 'react';
import {
  Commodity,
  Project,
  Task,
  Transaction,
  TransactionSchema,
} from 'shared';

export default function useCreateTransaction(
  refresh: () => void,
  parent?: Project | Task,
) {
  const { showModal } = useModal();

  return () => {
    if (!parent) return;
    showModal(
      'New Transaction',
      <CreateTransactionModal parent={parent} refresh={refresh} />,
      parent.name,
    );
  };
}

function CreateTransactionModal({
  parent,
  refresh,
}: {
  parent: Project | Task;
  refresh: () => void;
}) {
  const ctx = useCurrentModal();

  const commodities = useCommodities({
    type: parent.type,
    id: parent._id,
  });

  const commoditiesMap = useMemo(
    () =>
      commodities.data
        ? commodities.data?.reduce(
            (acc, c) => ((acc[c._id] = c), acc),
            {} as Record<string, Commodity>,
          )
        : null,
    [commodities.data],
  );

  // new Project().deadline;

  const [taskTemplate, setTaskTemplate] = useState<Transaction>({
    _id: '',
    description: '',
    name: '',
    commodities: [],
    created: new Date(),
    parent: {
      id: parent._id,
      type: parent.type,
    },
    parentPaths: [],
    type: 'transaction',
    status: 'pending',
    amount: 0,
    merchant: '',
    credit: false,
    children: [],
  });

  const [created, setCreated] = useState<Moment | null>(null);

  const selectedCommodities = useMemo(
    () =>
      commoditiesMap
        ? taskTemplate.commodities.map((x) => ({
            ...commoditiesMap[x.id],
            quantity: x.quantity,
          }))
        : [],
    [commoditiesMap, taskTemplate.commodities],
  );

  const subtotal = useMemo(
    () =>
      selectedCommodities.reduce(
        (acc, c) => (acc += (c.quantity ?? 0) * c.price),
        0,
      ),
    [selectedCommodities],
  );

  return (
    <Stack gap={2}>
      <Stack gap={3} direction="row">
        <Stack gap={2} flexGrow={1} minWidth={800}>
          <Typography variant="h6">Basic Info</Typography>
          <TextField
            required
            error={!taskTemplate.name.trim().length}
            label="Name"
            value={taskTemplate.name}
            onChange={(e) =>
              setTaskTemplate((prev) => ({
                ...prev,
                name: e.target.value,
              }))
            }
          />
          <TextField
            helperText={
              taskTemplate.amount < subtotal
                ? 'Are you sure? The total is less than the subtotal.'
                : ''
            }
            InputProps={{
              startAdornment: '$',
            }}
            value={taskTemplate.amount}
            onChange={(e) =>
              setTaskTemplate((prev) => ({
                ...prev,
                amount: e.target.value as any,
              }))
            }
          />
          {/* <TextField
            label="Description"
            multiline
            minRows={10}
            required
            error={!taskTemplate.description.trim().length}
            value={taskTemplate.description}
            onChange={(e) =>
              setTaskTemplate((prev) => ({
                ...prev,
                description: e.target.value,
              }))
            }
          /> */}
          <Autocomplete
            multiple
            renderTags={() => null}
            options={commodities.data ?? []}
            getOptionLabel={(option) => `${option.brand} - ${option.name}`}
            value={selectedCommodities}
            onChange={(_, v) =>
              setTaskTemplate((prev) => ({
                ...prev,
                commodities: v.map((x) => ({
                  id: x._id,
                  quantity: x.quantity || 1,
                  type: 'commodity',
                })),
              }))
            }
            renderInput={(params) => (
              <TextField {...params} label="Add Commodities" />
            )}
          />
          <List>
            {selectedCommodities.map((commodity, i) => [
              ...(i > 0 ? [<Divider key={i} />] : []),
              <ListItem key={commodity._id}>
                <ListItemText
                  primary={commodity.name}
                  secondary={commodity.brand}
                />
                <Typography mx={2}>${commodity.price}</Typography>
                <NumberInput
                  value={commodity.quantity}
                  style={{ width: 100 }}
                  min={1}
                  onChange={(_, v) =>
                    setTaskTemplate((prev) => ({
                      ...prev,
                      commodities: prev.commodities.map((c) =>
                        c.id === commodity._id
                          ? { ...c, quantity: v ? v : c.quantity }
                          : c,
                      ),
                    }))
                  }
                />
                <ListItemIcon sx={{ minWidth: 0, ml: 2 }}>
                  <IconButton size="small" color="error">
                    <RemoveCircleOutline />
                  </IconButton>
                </ListItemIcon>
              </ListItem>,
            ])}
          </List>
        </Stack>
        <Stack gap={2} flexShrink={0} minWidth={250}>
          <Stack>
            <Typography variant="caption">Date</Typography>
            <DateTimePicker value={created} onChange={(v) => setCreated(v)} />
          </Stack>
          <Tooltip title="A credit transaction is when money comes in to an account as a result of selling.">
            <FormControlLabel
              control={<Checkbox />}
              label="Credit"
              value={taskTemplate.credit}
              onChange={(_, v) =>
                setTaskTemplate((prev) => ({
                  ...prev,
                  credit: v,
                }))
              }
            />
          </Tooltip>
        </Stack>
      </Stack>
      <Divider />
      {selectedCommodities.length ? (
        <List>
          {selectedCommodities.map((c) => (
            <ListItem key={c._id}>
              {!!c.imageUrl && <img src={c.imageUrl} width={50} height={50} />}
              <Typography ml={2} mr={1} minWidth={30}>
                {c.quantity}x
              </Typography>
              <Stack>
                <Typography>
                  {c.name} ({c.brand})
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  ${c.price} / each
                </Typography>
              </Stack>
              <Divider
                sx={{
                  flexGrow: 1,
                  minWidth: 50,
                  mx: '16px !important',
                  borderStyle: 'dashed',
                }}
              />
              <Typography>${c.price * (c.quantity ?? 0)}</Typography>
            </ListItem>
          ))}
        </List>
      ) : null}
      <Typography textAlign="right" mt={1}>
        {selectedCommodities.reduce((acc, x) => (acc += x.quantity ?? 0), 0)}
        &nbsp; items
      </Typography>
      <Divider sx={{ my: '16px !important' }} />
      <Stack
        gap={1}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h5">Subtotal</Typography>
        <Typography variant="h5">${subtotal.toFixed(2)}</Typography>
      </Stack>
      <Stack
        gap={1}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography fontWeight={400} variant="h4">
          Total
        </Typography>
        <Typography fontWeight={400} variant="h4">
          $
          {!isNaN(+taskTemplate.amount)
            ? (+taskTemplate.amount).toFixed(2)
            : '--.--'}
        </Typography>
      </Stack>
      <Stack gap={2} direction="row" justifyContent="flex-end">
        <Button color="error" onClick={() => ctx?.closeModal()}>
          Cancel
        </Button>
        <Button
          onClick={async () => {
            try {
              // eslint-disable-next-line no-unused-vars
              const { _id, amount, created: _, ...rest } = taskTemplate;
              if (isNaN(+amount)) return;
              const transactionPayload = TransactionSchema.parse({
                ...rest,
                created: created ? created?.toDate() : new Date(),
                amount: +amount,
              });

              await fetch(
                `${EndpointUrl}/${parent.type}/${parent._id}/transactions`,
                {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(transactionPayload),
                },
              );

              ctx?.closeModal();

              setTimeout(() => {
                refresh();
              }, 1000);
            } catch (e) {
              console.dir(e);
            }
          }}
        >
          Create Transaction
        </Button>
      </Stack>
    </Stack>
  );
}
